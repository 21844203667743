/* eslint-disable array-callback-return */
import {
  ADDONS_IMAGE,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  REMOVE_ADDONS,
  REMOVE_ADDONS_IMAGE,
  RESET_PRODUCT,
  UPDATE_ADDONS,
  UPDATE_ADDONS_PRICE,
  UPDATE_NOTE_PRODUCT,
  UPDATE_PRICE_ADJUSTMENT,
  UPDATE_PRICE_DESIGN,
  UPDATE_PRODUCT,
  UPDATE_QTY,
  UPDATE_SUBTOTAL,
  UPDATE_TOTAL_WEIGHT,
} from './type';

const initialState = {
  products: [],
  totalWeight: 0,
  addonPrice: 0,
  subTotal: 0,
  subTotalPartner: 0,
  subTotalInternal: 0,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return { ...state, products: [...state.products, action.payload] };
    case UPDATE_PRODUCT:
      return {
        ...state,
      };
    case UPDATE_QTY:
      return {
        ...state,
        products: state.products.map((product, index) =>
          product.key === action.payload.key ? { ...product, qty: action.payload.qty } : product
        ),
      };
    case UPDATE_PRICE_ADJUSTMENT:
      return {
        ...state,
        products: state.products.map((product, index) =>
          product?.key === action.payload.key
            ? { ...product, price_adjustment: parseInt(action.payload.value) }
            : product
        ),
      };
    case UPDATE_PRICE_DESIGN:
      return {
        ...state,
        products: state.products.map((product, index) =>
          product?.key === action.payload.key
            ? { ...product, price_design: parseInt(action.payload.price_design) }
            : product
        ),
      };
    case UPDATE_NOTE_PRODUCT:
      return {
        ...state,
        products: state.products.map((product, index) =>
          index === action.payload.idx ? { ...product, note: action.payload.value } : product
        ),
      };
    case UPDATE_ADDONS:
      return {
        ...state,
        products: state.products.map((product, index) =>
          product?.key === action.payload.key
            ? { ...product, addons: [...product.addons, action.payload.value] }
            : product
        ),
      };
    case REMOVE_ADDONS:
      return {
        ...state,
        products: state.products.map((product, index) => {
          if (product?.key === action.payload.key) {
            return {
              ...product,
              addons: product.addons.filter((addon) => addon.id !== action.payload.id),
            };
          } else {
            return product;
          }
        }),
      };
    case ADDONS_IMAGE:
      return {
        ...state,
        products: state.products.map((product, index) =>
          product.key === action.payload.key
            ? { ...product, images: [...product.images, action.payload.data] }
            : product
        ),
      };
    case REMOVE_ADDONS_IMAGE:
      return {
        ...state,
        products: state.products.map((product) => {
          if (product?.key === action.payload.key) {
            return {
              ...product,
              images: product.images.filter((image, index) => image.url !== action.payload.url),
            };
          } else {
            return product;
          }
        }),
      };
    case UPDATE_TOTAL_WEIGHT:
      return {
        ...state,
        totalWeight: state.products.reduce(
          (accumulator, variant) => accumulator + variant.variant.weight * variant.qty,
          0
        ),
      };
    case UPDATE_ADDONS_PRICE:
      const totalAddonPrice = state.products.reduce((addonSum, product) => {
        const addonsPrice = product.addons.reduce((addonSum, addon) => addonSum + addon.price, 0);
        return addonSum + addonsPrice;
      }, 0);
      return {
        ...state,
        addonPrice: totalAddonPrice,
      };
    case UPDATE_SUBTOTAL:
      const calculateSubTotal = (priceType) =>
        state.products.reduce(
          (accumulator, product) =>
            accumulator +
            (product.price_adjustment + product.price_design + product.variant[priceType]) *
              product.qty,
          0
        ) || 0;

      const subTotal = calculateSubTotal('price');
      const subTotalPartner = calculateSubTotal('partner_price');
      const subTotalInternal = calculateSubTotal('internal_price');

      return {
        ...state,
        subTotal,
        subTotalPartner,
        subTotalInternal,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        productKey: state.productKey - 1,
        products: state.products.filter((product) => product?.key !== action.payload),
      };
    case RESET_PRODUCT:
      return {
        products: [],
        productKey: 0,
        totalWeight: 0,
        addonPrice: 0,
        subTotal: 0,
      };
    // tambahkan case lain sesuai kebutuhan
    default:
      return state;
  }
};

export default productReducer;
