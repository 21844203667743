export const defaultState = {
  item_id: null,
  order_id: null,
  year: '',
  qty: 1,
  weight: 0,
  height: 0,
  width: 0,
  price: 0,
  price_design: 0,
  price_adjustment: 0,
  price_addons: 0,
  subtotal: 0,
  note: null,
  production_note: null,
  estimation: null,
  estimation_note: null,
  product: {
    name: '',
    id: null,
    is_ready_stock: 0,
    category: {
      id: null,
      name: '',
    },
  },
  variant: {
    id: null,
    row: null,
    size: 'ALL SIZE',
    weight: 0,
    price: 0,
    partner_price: 0,
    internal_price: 0,
  },
  transmission_type: null,
  color: { id: null, name: '', hex_code: null },
  histories: [],
  color_variant_1: null,
  color_variant_2: null,
  material: { id: null, name: '' },
  material_variant_1: null,
  material_variant_2: null,
  logo: null,
  fire_extinguisher: null,
  lock_hole: null,
  design: null,
  addons: [],
  images: [],
  status: {
    id: null,
    name: '',
    label: '',
    action: null,
    next_status_id: null,
    need_description: false,
  },
  order: {
    id: null,
    is_corporate: false,
    order_number: '',
    verification_type: '',
    transaction_token: null,
    platform: '',
    order_source: '',
    invoice_marketplace: null,
    bonus: [''],
    description: null,
    shipping_cost: '',
    subtotal: '',
    additional_cost: '0',
    additional_cost_note: null,
    discount: '0',
    gross_amount: '',
    tax_amount: '0',
    tax_rate: '0.00',
    admin_fee: '0',
    grand_total: '',
    payment_amount: '',
    payment_date: '',
    payment_due_date: null,
    payment_verified_at: null,
    order_date: '',
    created_at: '',
    updated_at: '',
    unit: { id: null, name: '' },
    dealmaker: {
      id: null,
      email: '',
      name: '',
    },
    customer_type: { id: 1, name: 'Retail' },
    payment_status: { id: null, name: '' },
    customer: {
      id: null,
      name: '',
      phone: '',
      email: null,
      address: '',
      postal_code: '',
      province: { id: null, name: '' },
      district: { id: null, name: '', type: '' },
      subdistrict: { id: null, subdistrict_code: '', name: '' },
    },
    items: [
      {
        item_id: null,
        order_id: null,
        year: '1000',
        qty: 1,
        weight: 0,
        price: 0,
        price_design: 0,
        price_adjustment: 0,
        price_addons: 0,
        subtotal: 0,
        note: null,
        production_note: null,
        estimation: null,
        estimation_note: null,
        color_variant_2: null,
        addons: [],
        variant: {
          id: null,
          row: null,
          size: 'ALL SIZE',
          weight: 0,
          price: 0,
          partner_price: 0,
          internal_price: 0,
        },
        material: { id: null, name: '' },
        material_variant_1: null,
        material_variant_2: null,
        transmission_type: null,
        logo: null,
        product: { name: '', id: null },
        color: { id: 8028, name: '', hex_code: null },
        color_variant_1: null,
        design: null,
        status: {
          id: null,
          name: '',
          label: '',
          action: null,
          next_status_id: null,
          need_description: false,
        },
        vehicle: {
          id: null,
          row: null,
          size: 'ALL SIZE',
          vehicle_model: {
            id: null,
            name: '',
            year_from: 1966,
            year_to: 1993,
            manufacturer: { id: null, name: '' },
          },
        },
      },
    ],
    shipment: {
      id: null,
      courier_code: '',
      courier_name: '',
      courier_service: '',
      total_weight: 0,
      tracking_number: null,
      shipping_date: null,
      name: '',
      email: null,
      phone: '',
      address: '',
      province_id: null,
      district_id: null,
      subdistrict_id: null,
      postal_code: '',
      subdistrict: { id: null, subdistrict_code: '', name: '' },
      province: { id: null, name: '' },
      district: { id: null, name: '', type: '' },
      shipper: {
        id: null,
        name: '',
        phone: '',
        address: '',
        postal_code: '',
        province: { id: null, name: '' },
        unit: { id: null, name: '' },
        subdistrict: { id: null, subdistrict_code: '', name: '' },
        district: { id: null, name: '', type: '' },
      },
    },
  },
  vehicle: {
    id: null,
    row: null,
    size: 'ALL SIZE',
    vehicle_model: {
      id: null,
      name: '',
      year_from: 1000,
      year_to: 2023,
      manufacturer: { id: null, name: '' },
    },
  },
};
