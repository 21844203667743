export const GET_STATUS_PAYMENT_SUCCESS = 'GET_STATUS_PAYMENT_SUCCESS';
export const STATUS_PAYMENT_ID = 'STATUS_PAYMENT_ID';

export const PAYMENT_DATE = 'PAYMENT_DATE';
export const PAYMENT_AMOUNT = 'PAYMENT_AMOUNT';

export const GET_PAYMENT_CHANNEL_SUCCESS = 'GET_PAYMENT_CHANNEL_SUCCESS';
export const PAYMENT_CHANNEL = 'PAYMENT_CHANNEL';

export const RESET_PAYMENT = 'RESET_PAYMENT';

export const IS_CORPORATE = 'IS_CORPORATE';
